export const ensureTrailingSlash = (url: string) => {
  // Add a slash if the url doesn't end with one and doesn't contain a hash or a query string
  return url.endsWith('/') && !url?.includes('#') && !url?.includes('?') ? url : `${url}/`
}

export const ensureLeadingSlash = (url: string) => {
  // Adds a leading slash if the url doesn't start with one or doesn't contain a protocol
  return url.startsWith('/') || url?.includes('://') ? url : `/${url}`
}

export const ensureLeadingTrailingSlash = (url: string) => {
  return ensureLeadingSlash(ensureTrailingSlash(url))
}

export const removeTrailingSlash = (url: string) => {
  return url.endsWith('/') ? url.substring(0, url.length - 1) : url
}

export const removeLeadingSlash = (url: string) => {
  return url.startsWith('/') ? url.substring(1, url.length) : url
}

export const ensureUrlWithProtocol = (url: string, defaultProtocol = 'https') => {
  // Adds a protocol if the url doesn't start with any protocol
  return url?.match(/^(?:\w+?:\/\/)/) ? url : `${defaultProtocol}://${url}`
}
