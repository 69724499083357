'use client'
import React, { createContext, useContext, useEffect, useState } from 'react'
import useSearchParamsValues from '@/hooks/use-search-params-values'
import { addUtmParamsToSession, getDefaultUtmParams } from './utm-to-session-utils'

export const UTM_PARAMS_SESSION_KEY = 'synalismkt'

export interface SessionInterface {
  [key: string]: unknown
}

type SessionContextType = {
  session: SessionInterface | null
  setSession: React.Dispatch<React.SetStateAction<SessionInterface | null>>
}

type SessionContextProviderProps = {
  children: React.ReactNode
}

const getCurrentSession = (): SessionInterface => {
  const sessionKeys = Object.keys(sessionStorage)
  const session = sessionKeys.reduce((acc, key) => {
    const value = sessionStorage.getItem(key)
    if (value) {
      try {
        acc[key] = JSON.parse(value)
      } catch {
        acc[key] = value as string
      }
    }
    return acc
  }, {} as SessionInterface)
  return session
}

const setInSessionStorage = (session: SessionInterface) => {
  Object.keys(session).map((key) => {
    sessionStorage.setItem(key, JSON.stringify(session[key]))
  })
}

const SessionContext = createContext<SessionContextType>({} as SessionContextType)

const useSetInSession = (key: string, value: unknown) => {
  const { session, setSession } = useContext(SessionContext)
  const setInSession = (key: string, value: unknown) => {
    const newSession = { ...session, [key]: value }
    setSession(newSession as SessionInterface)
    setInSessionStorage(newSession)
  }
  return setInSession(key, value)
}

const useGetFromSession = (key: string) => {
  const { session } = useContext(SessionContext)
  const getFromSession = (key: string) => {
    if (session?.[key]) {
      return session[key]
    }
    return null
  }
  return getFromSession(key)
}

const useSession = () => {
  const { session } = useContext(SessionContext)
  return session
}

const SessionContextProvider = ({ children }: SessionContextProviderProps) => {
  const params = useSearchParamsValues()
  const [session, setSession] = useState<SessionInterface | null>(null)

  useEffect(() => {
    const initialSession = getCurrentSession()
    const augmentedSession = addUtmParamsToSession(initialSession, params, getDefaultUtmParams(document.referrer))
    setInSessionStorage(augmentedSession)
    setSession(augmentedSession)
  }, [setSession, params])

  return <SessionContext.Provider value={{ session, setSession }}>{children}</SessionContext.Provider>
}

export { useSession, SessionContextProvider, useSetInSession, useGetFromSession }
