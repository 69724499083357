import type { AnchorHTMLAttributes, ButtonHTMLAttributes, FC, MouseEvent, ReactNode } from 'react'
import cx from 'clsx'
import tw from 'tailwind-styled-components'
import SafeLink from '@/components/atoms/safe-link'
import { ButtonVariant } from '@/types/site.interface'

export type ButtonType = 'anchor' | 'button'

export interface Button {
  children: ReactNode | string
  className?: string
  disabled?: boolean
  submit?: boolean
  href?: string
  type?: ButtonType
  variant?: ButtonVariant
  onClick?: (e: MouseEvent) => void
  fullWidth?: boolean
  form?: string
  _key?: string
  newTab?: boolean
}

interface StyledButton {
  disabled?: boolean
  fullWidth?: boolean
}

const StyledPrimaryButton = tw.a<StyledButton>`
    inline-flex
    justify-center
    rounded-md
    border
    border-transparent
    py-2
    px-6
    text-base
    font-semibold
    text-center
    shadow-sm
    focus:outline-none
    transition
    bg-button-bg
    text-button-text
    ${({ fullWidth }) => (fullWidth ? 'w-full' : '')}
    ${(p) => (p.disabled ? 'opacity-25 cursor-default' : `hover:bg-opacity-80 `)}
`

const StyledSecondaryButton = tw.a<StyledButton>`
    inline-flex
    justify-center
    rounded-md
    py-2
    px-6
    text-base
    font-semibold
    text-center
    text-primary
    border-primary
    border-2
    shadow
    ${(p) =>
      p.disabled ? 'opacity-25 cursor-default' : `hover:bg-primary hover:bg-opacity-10 hover:border-opacity-50`}
`
export const Button: FC<Button> = ({
  children,
  className,
  disabled = false,
  type = 'anchor',
  submit,
  href,
  variant = 'primary',
  onClick,
  fullWidth,
  form,
  newTab,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component: FC<AnchorHTMLAttributes<Button>> | FC<ButtonHTMLAttributes<Button>> | any =
    variant === 'secondary' ? StyledSecondaryButton : StyledPrimaryButton

  const classes = cx({ 'w-full': fullWidth }, className)

  return type === 'anchor' ? (
    <Component
      href={href}
      $as={SafeLink}
      disabled={disabled}
      target={newTab ? '_blank' : undefined}
      className={classes}
      onClick={!disabled ? onClick : undefined}
      scroll={true}
    >
      {children}
    </Component>
  ) : (
    <Component
      $as="button"
      type={submit ? 'submit' : undefined}
      disabled={disabled}
      className={classes}
      onClick={!disabled ? onClick : undefined}
      form={form}
    >
      {children}
    </Component>
  )
}
