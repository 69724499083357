import { SessionInterface } from '@/lib/session-context'

export const UTM_PARAMS_SESSION_KEY = 'synalismkt'

export interface UtmParams {
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
}

const extractUTM = (searchParams: URLSearchParams | null) => {
  const utm = {}
  if (!searchParams) return utm

  searchParams.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utm[key] = value
    }
  })
  return utm
}

export const getDefaultUtmParams = (referrer: string): UtmParams => {
  if (referrer.startsWith('https://www.google.com')) {
    return {
      utm_source: 'google',
      utm_medium: 'organic',
    }
  }
  return {}
}
export const addUtmParamsToSession = (
  sessionObject: SessionInterface,
  searchParams: URLSearchParams | null,
  defaultUtm: UtmParams = {},
): SessionInterface => {
  const extractedUtm = extractUTM(searchParams)
  const utm = { ...defaultUtm, ...extractedUtm }
  if (Object.keys(utm).length === 0) {
    return sessionObject
  }
  const storedUTM = sessionObject[UTM_PARAMS_SESSION_KEY] ?? {}
  const newUTM = { ...storedUTM, ...utm }
  return { ...sessionObject, [UTM_PARAMS_SESSION_KEY]: newUTM }
}
