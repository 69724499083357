import BaseSafeLink, { SafeLinkProps as BaseSafeLinkProps } from '@ignition/library/components/atoms/safe-link'
import AugmentedLink from './augmented-link'

export type SafeLinkProps = BaseSafeLinkProps

const shouldAugment = (props: SafeLinkProps) => {
  return (props.href && /^http(s)*:\/\/portal.stepin.de(\/)*.*$/.test(props.href.toString())) || false
}

const SafeLink = (props: SafeLinkProps) => {
  if (shouldAugment(props)) {
    return <AugmentedLink {...props}>{props.children}</AugmentedLink>
  }
  return <BaseSafeLink {...props}>{props.children}</BaseSafeLink>
}

export default SafeLink
