import { ClientPerspective, createClient } from 'next-sanity'
import { QueryParams } from 'sanity'
import { Preview } from '@/types/site.interface'
import sanityImage from '@sanity/image-url'

const options = {
  dataset: process.env.NEXT_PUBLIC_SANITY_PROJECT_DATASET,
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  useCdn: true,
  apiVersion: '2023-05-03',
  perspective: 'published' as ClientPerspective,
}

export const sanityClient = createClient({ ...options })
export const imageBuilder = sanityImage(sanityClient)

export function createPreviewClient(token: string) {
  return createClient({
    ...options,
    useCdn: false,
    token,
    ignoreBrowserTokenWarning: true,
    perspective: 'previewDrafts',
    stega: {
      enabled: false,
      studioUrl: '/studio',
    },
  })
}

export function getSanityClient(preview?: Preview) {
  if (preview?.active && preview?.token) {
    return createPreviewClient(preview.token)
  } else {
    return sanityClient
  }
}

interface NextFetchRequestConfig {
  /**
   * Adds a `revalidate` number - (in seconds) Specify the resource should have a cache lifetime of at most n seconds.
   */
  revalidate?: number | false
  /**
   * Adds `tags` string[] - to be revalidated on-demand using revalidateTag. The max length for a custom tag is 256 characters.
   */
  tags?: string[]
}

export async function fetchSanityClient(
  query: string,
  queryParams?: QueryParams,
  next?: NextFetchRequestConfig,
  preview?: Preview,
) {
  const client = await getSanityClient(preview)
  const daftEnable = preview?.active

  return client.fetch(query, queryParams, {
    cache: daftEnable || !!next?.revalidate ? undefined : 'force-cache',
    next: {
      ...(daftEnable && { revalidate: 30 }),
      ...next,
    },
  })
}

// Sanity client form submission
const optionsWriterCredentials = {
  withCredentials: true,
  token: process.env.SANITY_SUBMISSION_API_TOKEN,
}

export const submissionSanityClient = createClient({ ...options, ...optionsWriterCredentials })
