import { useEffect, useState } from 'react'

const useSearchParamsValues = (): URLSearchParams | null => {
  const [searchParams, setSearchParams] = useState<URLSearchParams | null>(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setSearchParams(new URLSearchParams(window.location.search))
    }
  }, [])

  return searchParams
}

export default useSearchParamsValues
