'use client'
import React, { FC } from 'react'
import cx from 'clsx'
import { useNextSanityImage, UseNextSanityImageBuilder } from 'next-sanity-image'
import { ImageUrlBuilder, UseNextSanityImageBuilderOptions } from 'next-sanity-image'
import { Image as PhosphorImage } from 'phosphor-react-sc'
import { sanityClient } from '@/lib/sanity'
import { ImageAsset } from '@/types/site.interface'
import Image from '@ignition/library/components/atoms/image'
import { SanityImageSource } from '@sanity/image-url/lib/types/types'

/*eslint-disable react-hooks/rules-of-hooks*/
export const nextSanityImage = (asset: SanityImageSource, customBuilder?: UseNextSanityImageBuilder) => {
  return {
    ...useNextSanityImage(sanityClient, asset, { imageBuilder: customBuilder }),
  }
}

type ObjectPosition =
  | 'object-bottom'
  | 'object-center'
  | 'object-left'
  | 'object-left-bottom'
  | 'object-left-top'
  | 'object-right'
  | 'object-right-bottom'
  | 'object-right-top'
  | 'object-top'
  | 'object-center'
  | 'object-center-bottom'

interface ImageElement {
  alt?: string
  image?: ImageAsset
  sizes?: string
  quality?: number
  responsive?: boolean
  className?: string
  imgClassName?: string
  useFallbackImage?: boolean
  objectPosition?: ObjectPosition
  priority?: boolean
  fill?: boolean
  fixedWidth?: number
  fixedHeight?: number
}

// define our aspect ratio
export const aspectRatio = (customRatio?: string) => {
  if (customRatio) {
    switch (customRatio) {
      case '1:1':
        return 'aspect-square'
      case '2:1':
        return 'aspect-2/1'
      case '3:1':
        return 'aspect-3/1'
      case '5:7':
        return 'aspect-5/7'
      case '4:6':
        return 'aspect-4/6'
      case '16:9':
        return 'aspect-video'
      default:
        return 'aspect-auto'
    }
  }
}

const defaultSizes = `
  (min-width: 250px) 50vw,
  (min-width: 450px) 60vw,
  (min-width: 720px) 70vw,
  (min-width: 1024px) 80vw,
  (min-width: 1280px) 90vw, 100vw
`

const ImageElement: FC<ImageElement> = ({
  alt,
  image,
  sizes = defaultSizes, // without sizes the user downloads an image 9 times larger than necessary.
  responsive,
  className,
  imgClassName,
  priority = false,
  objectPosition,
  useFallbackImage = false,
  fill,
  fixedHeight,
  fixedWidth,
}) => {
  if (!image || !image?.asset) {
    return useFallbackImage ? <PhosphorImage className="w-full h-full text-gray-300" weight="light" /> : null
  }

  const customImageBuilder = (imageUrlBuilder: ImageUrlBuilder, options: UseNextSanityImageBuilderOptions) => {
    const quality = options.quality || 75
    const originalWidth = options?.originalImageDimensions?.width
    const widthToUse = options.width || Math.min(originalWidth, 1550)
    return imageUrlBuilder
      .width(fixedWidth ?? widthToUse)
      .height(fixedHeight ?? widthToUse)
      .quality(quality)
      .fit('clip')
      .auto('format')
  }

  const { width, height, ...sanityProps } = nextSanityImage(image, fill ? customImageBuilder : undefined)
  return (
    <picture className={cx(aspectRatio(image.customRatio), className)} style={{ aspectRatio: image?.aspectRatio }}>
      <Image
        {...sanityProps}
        sizes={sizes}
        fill={fill}
        {...(fill ? {} : { width, height })}
        alt={alt || image.alt || image.asset?.alt || ''}
        className={cx(objectPosition, responsive && 'object-contain', fill && 'object-cover', imgClassName)}
        priority={priority}
        title={image.title}
        copyright={image.copyright}
        blurDataURL={image.lqip}
      />
    </picture>
  )
}

export default ImageElement
