'use client'

import { useGetFromSession } from '@/lib/session-context'
import { UTM_PARAMS_SESSION_KEY } from '@/lib/utm-to-session-utils'
import BaseSafeLink, { SafeLinkProps } from '@ignition/library/components/atoms/safe-link'

export type AugmentedLinkProps = SafeLinkProps

const AugmentedLink = (props: AugmentedLinkProps) => {
  const utm = useGetFromSession(UTM_PARAMS_SESSION_KEY) as { [key: string]: string } | null
  if (props.href && utm) {
    const url = new URL(props.href.toString())
    Object.entries(utm).forEach(([key, value]) => {
      url.searchParams.set(key, value)
    })
    return (
      <BaseSafeLink {...props} href={url.toString()}>
        {props.children}
      </BaseSafeLink>
    )
  }
  return <BaseSafeLink {...props}>{props.children}</BaseSafeLink>
}

export default AugmentedLink
